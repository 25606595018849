/* eslint-disable max-len */
import { BaseBulkOperationsModel } from '../BaseBulkOperationsModel';
import { NotificationSignalRModel } from '@/signalRRequest/Notification/NotificationSignalRModel';
export class NotificationBulkOperation extends BaseBulkOperationsModel {
  constructor(rootObj: any, $store: any, $t: any) {
    super();
    const signalR: NotificationSignalRModel = new NotificationSignalRModel(rootObj, $store, $t);

    this.arrBulkOperations.push({ Id: 1, Name: 'Export Dialogues', IconUrl: 'fa fa-file-excel-o font-awesome-icon', IconPadding: 'padding:17px 19px 17px 17px;', Description: 'Export responses from attendees in excel file', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.ExportSelectedNotificationSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 2, Name: 'Delete Dialogues', IconUrl: 'fa fa-trash-o font-awesome-icon', IconPadding: 'padding:17px;', Description: 'Delete selected Dialogue Items', ConfirmationMessage: 'Notifications.SureDeleteDialogueItems', ConfirmationMessageSubTitle: '', objModel: signalR.DeleteSelectedNotificationSignalRModelRequest, ShowConfirmationPopup: true });

    this.arrBulkOperations.push({ Id: 4, Name: 'Change Folders', IconUrl: 'fa fa-folder-open font-awesome-icon', IconPadding: 'padding:17px 12px 17px 17px', Description: 'Change folder destination for selected item', ConfirmationMessage: '', ConfirmationMessageSubTitle: '', objModel: signalR.BulkAssignFolderSignalRModelRequest, ShowConfirmationPopup: false });

    this.arrBulkOperations.push({ Id: 3, Name: 'Clear Responses', IconUrl: 'fa fa-recycle font-awesome-icon', IconPadding: 'padding:17px 19px 17px 12px;', Description: 'Clear Responses of selected Dialogues', ConfirmationMessage: 'Notifications.SureClearResponsesDialogueItems', ConfirmationMessageSubTitle: '', objModel: signalR.ClearResponsesSelectedNotificationItemsSignalRModelRequest, ShowConfirmationPopup: true });
  }
}
