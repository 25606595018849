
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import NotificationFolderListViewEditItemPane from '@/components/Notification/Folder/NotificationFolderListViewEditItemPane.vue';
import { Root, t } from '@/main';
@Component({
  components: {
    NotificationFolderListViewEditItemPane,
  },
})
class NotificationFolderListView extends Vue {
  @Prop() private folderItems?: string[];
  @Prop() private applicationborderColor?: string;
  private localFolderItems: string[] = [];
  private localFolderItem: string | null = null;
  public loaderBorderColor = '';
  private readonly TopLevel: string = t('Dialogue.TopLevel').toString();

  private CloseFolder() {
    this.localFolderItem = null;
    this.$emit('notification-folder-close-operation', true);
    // Root.emit('clearCustomCategoryCheckboxes', []);
  }
  private mounted() {
    // Manage folder screen must not have Top level folder as it is default folder
    /* Use must not be able to delete edit temper Top level folder*/
    this.folderItems!.forEach((element) => {
      if (element !== this.TopLevel && element !== null) {
        this.localFolderItems.push(element);
      }
    });
    if (this.applicationborderColor != null) {
      this.loaderBorderColor = this.applicationborderColor;
    }
    Root.on('notification-folder-close-others-operation', () => {
      // this.currentEditFolderItem = null;
      this.localFolderItem = null;
    });
  }
  private NotificationFolderItemCancelOperation() {
    // close folder edit pane
    this.localFolderItem = null;
  }
  @Watch('folderItems')
  private onFolderListChange() {
    this.localFolderItems = [];
    this.folderItems!.forEach((element) => {
      if (element !== this.TopLevel && element !== null) {
        this.localFolderItems.push(element);
      }
    });
  }
}
export default toNative(NotificationFolderListView);
