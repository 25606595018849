
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import NotificationExportPane from '@/components/Notification/NotificationExport/NotificationExportPane.vue';
import { NotificationSegmentQuestion } from '@/entities/Notification/NotificationSegmentQuestion';
import { NotificationItem } from '@/entities/Notification/NotificationItem';

@Component({
  components: {
    NotificationExportPane,
  },
})

class NotificationExportPopup extends Vue {
  @Prop() private showExportPopup?: boolean;
  @Prop() private participantSegments?: NotificationSegmentQuestion[];
  @Prop() private notificationItems?: NotificationItem[];

  private selectedExportType = '';
  private selectedparticipantSegments?: NotificationSegmentQuestion[];

  private localExportShowPopup?: boolean = false;
  private exportPopupVisible = false;
  private localparticipantSegments?: NotificationSegmentQuestion[];

  private mounted() {
    this.localExportShowPopup = this.showExportPopup;
    this.exportPopupVisible = true;
    this.localparticipantSegments = this.participantSegments;
  }
  private onExportOptionsSelected(object: any) {
    this.selectedExportType = object.selectedtype;
    this.selectedparticipantSegments = object.segmentoptions;
  }
  private Cancel() {
    this.$emit('cancel-clicked');
  }
  private clickYes() {
    this.$emit('confirm-click-export', {
      selectedtype: this.selectedExportType,
      segmentoptions: this.selectedparticipantSegments,
      notificationOptions: this.notificationItems,
      exportFileName: 'Feedback_All.xlsx',
    });
  }
}
export default toNative(NotificationExportPopup);
