
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationFilter } from '@/entities/Notification/NotificationFilter';
import NotificationHeaderPane from '@/components/Notification/NotificationCommon/NotificationHeaderPane.vue';
import NotificationFooterPane from '@/components/Notification/NotificationCommon/NotificationFooterPane.vue';
import LinkNotificationWithEntity from '@/components/Notification/LinkNotificationWithEntity/LinkNotificationWithEntity.vue';
import AddInformationWithEntity from '@/components/AddInformation/AddInformationWithEntity.vue';
import AddProgramWithEntity from '@/components/AddProgram/AddProgramWithEntity.vue';
import ModuleEnum from '@/enums/ModuleEnum';
import { CustomCategoryItem } from '@/entities/CustomCategory/CustomCategoryItem';
import { CustomCategoryFolder } from '@/entities/CustomCategory/CustomCategoryFolder';
import { ProgramItem } from '@/entities/Program/ProgramItem';
import { LinkableItem } from '@/entities/LinkableItem/LinkableItem';
import { Root } from '@/main';
import UpdateNotificationCustomeCategoryItem from '@/entities/Emits/Notification/UpdateNotificationCustomeCategoryItem';
import UpdateNotificationProgramItem from '@/entities/Emits/Notification/UpdateNotificationProgramItem';

@Component({
  components: {
    loading,
    ModalPopup,
    NotificationHeaderPane,
    NotificationFooterPane,
    LinkNotificationWithEntity,
    AddInformationWithEntity,
    AddProgramWithEntity,
  },
})
class NotificationInformationMessageEditPane extends Vue {
  // Component properties
  @Prop() private selectedNotificationItem?: NotificationItem;
  @Prop() private folders?: string[];
  @Prop() private filters?: NotificationFilter[];
  @Prop() private availableCustomCategoryFolders?: CustomCategoryFolder[];
  @Prop() private availableCustomCategoryItems?: CustomCategoryItem[];
  @Prop() private availableProgramItems?: ProgramItem[];

  // Local varianbles
  private localNotificationItem: NotificationItem | null = null;
  private showLinkInformation = false;
  private showLinkProgram = false;
  private entityType = ModuleEnum.Dialogue;
  private localAllInformations: CustomCategoryItem[] = [];
  private localCustomCategoryFolders: CustomCategoryFolder[] = [];
  private localAllPrograms: ProgramItem[] = [];

  private created() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );
    this.localAllInformations = this.availableCustomCategoryItems!;
  }

  private mounted(){
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );
    this.localAllInformations = this.availableCustomCategoryItems!;
    this.localAllPrograms = this.availableProgramItems!;
    Root.on('update-notification-customecategory-item', (updateCustomCategoryItems: UpdateNotificationCustomeCategoryItem) => {
      this.localAllInformations =  updateCustomCategoryItems.LinkableCustomCategories;
      this.SetLinkedInformationItemDataInLocalNotificationObject(updateCustomCategoryItems.LinkableCustomCategories);
    });
    Root.on('update-notification-program-item', (updateProgramItems: UpdateNotificationProgramItem) => {
      this.localAllPrograms = updateProgramItems.LinkablePrograms;
      this.SetLinkedProgramItemDataInLocalNotificationObject(updateProgramItems.LinkablePrograms);
    });
  }
  // #region header
  private SetlocalNotificationHeaderProperties(
    notificationItem: NotificationItem,
  ) {
    // Set following Notification header properties
    this.localNotificationItem!.Title = notificationItem.Title;
    this.localNotificationItem!.Description = notificationItem.Description;
    this.localNotificationItem!.IsSentPublish = notificationItem.IsSentPublish;
    this.localNotificationItem!.NotificationTypeDescription = notificationItem.NotificationTypeDescription;
    this.localNotificationItem!.PublishedDateTime = notificationItem.PublishedDateTime;
    this.localNotificationItem!.ScheduleDate = notificationItem.ScheduleDate;
    this.localNotificationItem!.ScheduleUTCDate = notificationItem.ScheduleUTCDate;
  }
  // #endregion header

  // #region footer
  private SetSelectedFolder(folder: string) {
    this.localNotificationItem!.Folder = folder;
  }
  private SetAccessCode(accessCode: string) {
    this.localNotificationItem!.Code = accessCode;
  }
  private SetFilter(filetrId: number) {
    // Set filter Id and Filter name for Saving
    this.localNotificationItem!.FilterId = filetrId;
    if (filetrId > 0) {
      const objindex = this.filters!.findIndex(
        (obj: NotificationFilter) => obj.Id === filetrId,
      );
      if (objindex > -1) {
        this.localNotificationItem!.Filter = this.filters![
          objindex
        ].FilterName;
      }
    }
  }

  private showAttachInformation(val: any){
    if (val === ModuleEnum.Program) {
      this.showLinkProgram = val;
    } else{
      this.showLinkInformation = val;
    }

  }
  private hideAttachInformation(val: any){
    Root.emit('notification-hide-edit-item-buttons', true);
    this.showLinkInformation = val.showAddInformation;
    this.localAllInformations = val.informations;
    setTimeout(() => {
      Root.emit('linkedInformationData', val.informations);
    }, 500);
    this.SetLinkedInformationItemDataInLocalNotificationObject(val.informations);
  }
  private hideAttachProgram(val: any){
    Root.emit('notification-hide-edit-item-buttons', true);
    this.showLinkProgram = val.showAddPrograms;
    this.localAllPrograms = val.programs;
    setTimeout(() => {
      Root.emit('linked-program-data', val.programs);
    }, 500);
    this.SetLinkedProgramItemDataInLocalNotificationObject(val.programs);
  }

  private SetLinkedProgramItemDataInLocalNotificationObject(val: any){
    if((this.localNotificationItem!.LinkableItems === null || this.localNotificationItem!.LinkableItems === undefined)) {
      this.localNotificationItem!.LinkableItems = LinkableItem.createEmpty();
    }
    if (val !== null && val!== undefined) {
      val.forEach((element: any) => {
        if (element.IsLinked) {
          this.localNotificationItem!.LinkableItems!.EntityId = element.Id;
          this.localNotificationItem!.LinkableItems!.EntityType = ModuleEnum.Program;
          this.localNotificationItem!.LinkableItems!.IsLinked = true;
          return;
        }
      });
    }
  }
  private SetLinkedInformationItemDataInLocalNotificationObject(val: any){
    if (this.localNotificationItem!.LinkableItems === null || this.localNotificationItem!.LinkableItems === undefined) {
      this.localNotificationItem!.LinkableItems = LinkableItem.createEmpty();
    }
    if (val !== null && val!== undefined) {
      val.forEach((element: any) => {
        if (element.IsLinked) {
          this.localNotificationItem!.LinkableItems!.EntityId = element.CustomCategoryId;
          this.localNotificationItem!.LinkableItems!.EntityType = ModuleEnum.Information;
          this.localNotificationItem!.LinkableItems!.IsLinked = true;
          return;
        }
      });
    }
  }
  private SendNotificationRequestToQueueForEntity() {
    Root.emit('notification-send-to-signalr', false);
  }
  // #endregion footer

  // #region Watch
  @Watch('selectedNotificationItem')
  private onItemPropertyChange(
    val: NotificationItem,
  ) {
    // Updating local Notification object
    this.localNotificationItem = val;
  }
  @Watch('localNotificationItem')
  private onlocalItemPropertyChange(
    newval: NotificationItem,
  ) {
    // any edits made in Local Notification Object must be reflected in NotificationEditItemPane
    Root.emit('notification-edited', newval);
  }
}
export default toNative(NotificationInformationMessageEditPane);
// #endregion Watch
