import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73af1fb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = { class: "body-heading" }
const _hoisted_3 = { class: "body-text-content" }
const _hoisted_4 = { class: "body-content export-options-alignment" }
const _hoisted_5 = { class: "description" }
const _hoisted_6 = { class: "body-content export-options-alignment" }
const _hoisted_7 = { class: "description" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "custom-export-heading" }
const _hoisted_10 = ["index"]
const _hoisted_11 = ["id", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("Dialogue.ExportHeaderContent")), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("Dialogue.ExportBodyContent")), 1),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("label", _hoisted_5, [
        _createElementVNode("input", {
          class: "export-options",
          type: "radio",
          name: "exporttypeCheckboxgroup",
          checked: "true",
          onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.checkedStandard()))
        }, null, 32),
        _createTextVNode(_toDisplayString(_ctx.$t("Dialogue.StandardExport")), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("label", _hoisted_7, [
        _createElementVNode("input", {
          class: "export-options",
          type: "radio",
          name: "exporttypeCheckboxgroup",
          onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkedCustom()))
        }, null, 32),
        _createTextVNode(_toDisplayString(_ctx.$t("Dialogue.CustomExport")), 1)
      ])
    ]),
    (_ctx.isCustomSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("Dialogue.CustomExportHeading")), 1),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.IsScrollVisible(_ctx.isScrollable)])
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.participantSegmentsExportPane, (segment, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: segment.Id,
                index: index,
                class: "body-content export-options-alignment"
              }, [
                _createElementVNode("label", null, [
                  _withDirectives(_createElementVNode("input", {
                    id: 'si_' + segment.Id,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItems) = $event)),
                    class: "export-filters",
                    type: "checkbox",
                    value: segment
                  }, null, 8, _hoisted_11), [
                    [_vModelCheckbox, _ctx.selectedItems]
                  ]),
                  _createTextVNode(" " + _toDisplayString(segment.Name), 1)
                ])
              ], 8, _hoisted_10))
            }), 128))
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ]))
}