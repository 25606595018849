/* eslint-disable max-len */
import { BaseSignalRModelRequestJSON } from '@/signalRRequest/SignalRModelRequest';
import Common from '@/helper/Common';
import { NotificationOption } from '@/entities/Notification/NotificationOption';
import { ChoiceNotificationResponseSummary, DialogueScalingNotificationResponseSummary, RankNotificationResponseSummary, UserFeedbackModel,DialogueWordcloudData, PowerPointToken } from '@/entities/Notification/NotificationResults';
import { NotificationDimension } from '@/entities/Notification/NotificationDimension';
import { LinkableItem } from '../LinkableItem/LinkableItem';
export class NotificationItem {
  public static createEmpty(): NotificationItem {
    return new NotificationItem();
  }

  public static GetNotificationItemFromNotificationItemSignalR(item: NotificationItem, notificationItem: NotificationItemSignalR): NotificationItem {
    item.Id = notificationItem.Id;
    item.Title = notificationItem.Title;
    item.ImageFullUrl = notificationItem.ImageFullUrl;
    item.ImageThumbnailUrl = notificationItem.ImageThumbnailUrl;
    item.Folder = notificationItem.Folder;
    item.Description = notificationItem.Description;
    item.IsSentPublish = notificationItem.IsSentPublish;
    item.NotificationType = notificationItem.NotificationType;
    item.NotificationTypeId = notificationItem.NotificationTypeId;
    item.NotificationTypeDescription = notificationItem.NotificationTypeDescription;
    item.NotificationOptions = notificationItem.NotificationOptions;
    item.NotificationDimensions = notificationItem.NotificationDimensions;
    item.EnableClientShowResults = notificationItem.EnableClientShowResults;
    item.EnableResponseAnonymously = notificationItem.EnableResponseAnonymously;
    item.IsRespondOnlyOnce = notificationItem.IsRespondOnlyOnce;
    item.IsAllowNoResponse = notificationItem.IsAllowNoResponse;
    item.FeaturedImageWidth = notificationItem.FeaturedImageWidth;
    item.FeaturedImageHeight = notificationItem.FeaturedImageHeight;
    item.TopPosition = notificationItem.TopPosition;
    item.LeftPosition = notificationItem.LeftPosition;
    item.ShowFeatureImageBelowTitle = notificationItem.ShowFeatureImageBelowTitle;
    item.Filter = notificationItem.Filter;
    item.FilterId = notificationItem.FilterId;
    item.Filter = notificationItem.Filter;
    item.FilterId = notificationItem.FilterId;
    item.Code = notificationItem.Code;
    item.SortOrder = notificationItem.SortOrder;
    item.IsDeleted = notificationItem.IsDeleted;
    item.CreatedDate = notificationItem.CreatedDate;
    item.PublishNow = notificationItem.PublishNow;
    item.IsPublished = notificationItem.IsPublished;
    item.PublishDate = notificationItem.PublishDate;
    item.PublishedDateTime = notificationItem.PublishedDateTime;
    item.ScheduleDate = notificationItem.ScheduleDate;
    item.ScheduleUTCDate = notificationItem.ScheduleUTCDate;
    item.NotificationFeedbacks = notificationItem.NotificationFeedbacks;
    item.Timeoffset = notificationItem.Timeoffset;
    item.IsScheduled = notificationItem.IsScheduled;
    item.SelectedChartTypeId = notificationItem.SelectedChartTypeId;
    item.PowerPointToken = notificationItem.PowerPointToken;
    item.CountOfRemainingRemindersToSend = notificationItem.CountOfRemainingRemindersToSend;
    item.ShowCorrectFeedbackToClients = notificationItem.ShowCorrectFeedbackToClients;
    return item;
  }
  constructor(
    public Id: number= 0,
    public CreatedDate: Date = new Date(),
    public IsPublished: boolean = false,
    public PublishDate: Date = new Date(),
    public PublishedDateTime: Date = new Date(),
    public ScheduleDate?: Date,
    public ScheduleUTCDate: Date = new Date(),
    public Title: string = '',
    public Description: string= '',
    public Folder: string= '',
    public Notificationurl: string= '',
    public IsDeleted: boolean= false,
    public StyleDefaultDiv: string = '#f9f9f9',
    public SortOrder: number = 0,
    public NotificationOptions?: NotificationOption[] | NotificationOption[],
    public Code: string= '',
    public DisableItem?: boolean,
    public NotificationType: string = '',
    public NotificationTypeId: number= -1,
    public NotificationTypeDescription: string= '',
    public ImageFullUrl: string= '',
    public ImageThumbnailUrl: string= '',
    public NotificationFeedbacks: number= 0,
    public Responses: number= 0,
    public FeaturedImageWidth: number = 320, // default featured image width
    public FeaturedImageHeight: number = 200, // default featured image height
    public TopPosition: string = '0',
    public LeftPosition: string = '0',
    public ShowFeatureImageBelowTitle: boolean = false,
    public IsRespondOnlyOnce: boolean = false,
    public  EnableClientShowResults: boolean = false,
    public EnableResponseAnonymously: boolean = false,
    public IsAllowNoResponse: boolean = false,
    public IsSentPublish: boolean = false,
    public NotificationDimensions?: NotificationDimension [] | NotificationDimension[],
    public NotificationGroup: string = '',
    public NotificationGroupId: number = 0,
    public Filter: string = '',
    public FilterId: number = 0,
    public CreatedBy: string = '',
    public RecipientCount: number = 0,
    public Modified: Date = new Date(),
    public isVisibleToClients: boolean = true,
    public IsIncludeImage: boolean = false,
    public DistinctFeedbackUserCount: number = 0,
    public HasFeedback: boolean= false,
    public PublishNow: string = 'true',
    public QuestionnaireNotifications?: NotificationItem[] | NotificationItem[],
    public ParentQuestionnaireId: number = 0,
    public IsSurvey: boolean = false,
    public IsNewSurveyItem: boolean = false,
    public IsCreateNewItemInSurvey: boolean = false,
    public selectedName: string = '',
    public selectedCount: number = 1,
    public creatAnother: boolean = false,
    public Timeoffset: number = 0,
    public HighChartData?: ChoiceNotificationResponseSummary,
    public RankingHighChartData?: RankNotificationResponseSummary,
    public ScalingHighChartData?: DialogueScalingNotificationResponseSummary,
    public UserFeedback?: UserFeedbackModel[] | [],
    public IsScheduled: boolean = false,
    public IsItemDirty: boolean = false,
    public CopiedNotificationId: number = 0,
    public ShowCorrectFeedbackToClients: boolean = false,
    public SelectedChartTypeId: number = 0,
    public Wordclouds?: DialogueWordcloudData,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public PowerPointToken?: PowerPointToken,
    public CountOfRemainingRemindersToSend: number = 0,
    public LinkableItems?: LinkableItem,
    //  public LinkableProgramItem?: ProgramItem[],
    // public CustomCategoryFolders?: CustomCategoryFolder[],
    public IsAnyLinkedItemWithNotification: boolean = false,
    // public CopiedNotificationId: number = 0,
  ) {}

  public CompareNotificationObjects({ val1, val2 }: { val1: NotificationItem; val2: NotificationItem }): boolean {
    const notificationItem1: NotificationItem = NotificationItem.prototype.SetNotificationObject(val1);
    const notificationItem2: NotificationItem = NotificationItem.prototype.SetNotificationObject(val2);

    if (Common.prototype.CompareObjects(notificationItem1, notificationItem2) &&
                        this.CompareOptions(notificationItem1, notificationItem2) &&
                        this.CompareDimensions(notificationItem1, notificationItem2) &&
                        this.CompareSubItems(val1, val2)) {
      return true;
    } else {
      return false;
    }
  }
  public CompareOptions(val1: NotificationItem, val2: NotificationItem): boolean {
    let IsIdentical = true;

    if (val1.NotificationOptions !== undefined && val2.NotificationOptions !== undefined && val1.NotificationOptions !== null && val2.NotificationOptions !== null) {
      const noofOptionCurrently = val1.NotificationOptions.length;
      const noofOptionsPreviously = val2.NotificationOptions.length;
      if (noofOptionCurrently - noofOptionsPreviously !== 0) {
        IsIdentical = false;
      } else {
        for (let i = 0; i <= noofOptionCurrently - 1; i++) {
          if (val1.NotificationOptions[i].Text !== val2.NotificationOptions[i].Text) {
            IsIdentical = false;
          }
        }
      }
    } else {
      IsIdentical =  true;
    }
    return IsIdentical;
  }
  public CompareDimensions(val1: NotificationItem, val2: NotificationItem): boolean {
    let IsIdentical = true;

    if (val1.NotificationDimensions !== undefined && val2.NotificationDimensions !== undefined && val1.NotificationDimensions !== null && val2.NotificationDimensions !== null) {
      const noofOptionCurrently = val1.NotificationDimensions.length;
      const noofOptionsPreviously = val2.NotificationDimensions.length;
      if (noofOptionCurrently - noofOptionsPreviously !== 0) {
        IsIdentical = false;
      } else {
        for (let i = 0; i <= noofOptionCurrently - 1; i++) {

          if (val1.NotificationDimensions[i].NotificationDimensionLabel !== val2.NotificationDimensions[i].NotificationDimensionLabel) {
            IsIdentical = false;
          }
        }
      }
    } else {
      IsIdentical =  true;
    }
    return IsIdentical;
  }
  public CompareSubItems(val1: NotificationItem, val2: NotificationItem): boolean {
    let IsIdentical = true;

    if (val1.QuestionnaireNotifications !== undefined && val2.QuestionnaireNotifications !== undefined && val1.QuestionnaireNotifications !== null && val2.QuestionnaireNotifications !== null && val2.IsItemDirty) {
      const noofsubItemsCurrently = val1.QuestionnaireNotifications.length;
      const noofSubItemsPreviously = val2.QuestionnaireNotifications.length;
      if (noofsubItemsCurrently - noofSubItemsPreviously !== 0) {
        IsIdentical = false;
        return IsIdentical;
      } else {
        for (let i = 0; i <= noofsubItemsCurrently - 1; i++) {

          const subnotificationItem1: NotificationItem = NotificationItem.prototype.SetNotificationObject(val1.QuestionnaireNotifications[i]);

          const subnotificationItem2: NotificationItem = NotificationItem.prototype.SetNotificationObject(val2.QuestionnaireNotifications[i]);

          if (Common.prototype.CompareObjects(subnotificationItem1, subnotificationItem2) &&
                        this.CompareOptions(subnotificationItem1, subnotificationItem2) &&
                        this.CompareDimensions(subnotificationItem1, subnotificationItem2)) {
            IsIdentical = true;
          } else {
            IsIdentical = false;
            return IsIdentical;
          }
        }
      }
    } else {
      IsIdentical =  true;
    }
    return IsIdentical;
  }
  public SetNotificationObject(val: NotificationItem): any {
    return {
      Title: val.Title,
      Description: val.Description,
      Folder: val.Folder,
      ScheduleDate: val.ScheduleDate,
      ImageFullUrl: val.ImageFullUrl,
      NotificationOptions: val.NotificationOptions,
      Code: val.Code,
      IsRespondOnlyOnce: val.IsRespondOnlyOnce,
      EnableClientShowResults: val.EnableClientShowResults,
      EnableResponseAnonymously: val.EnableResponseAnonymously,
      IsSentPublish: val.IsSentPublish,
      NotificationDimensions: val.NotificationDimensions,
      FilterId: val.FilterId,
      NotificationTypeId: val.NotificationTypeId,
    };
  }

}
export class NotificationItemSignalR extends BaseSignalRModelRequestJSON {
  public static createEmpty(): NotificationItemSignalR {
    return new NotificationItemSignalR();
  }
  public static GetNotificationSignalRItem(notificationItem: NotificationItem): NotificationItemSignalR {
    const item: NotificationItemSignalR = NotificationItemSignalR.createEmpty();
    item.Id = notificationItem.Id;
    item.CreatedDate = notificationItem.CreatedDate;
    item.IsPublished = notificationItem.IsPublished;
    item.PublishDate = notificationItem.PublishDate;
    item.PublishedDateTime = notificationItem.PublishedDateTime;
    item.ScheduleDate = notificationItem.ScheduleDate;
    item.ScheduleUTCDate = notificationItem.ScheduleUTCDate;
    item.Title = notificationItem.Title;
    item.Description = notificationItem.Description;
    item.Folder = notificationItem.Folder;
    item.Notificationurl = notificationItem.Notificationurl;
    item.IsDeleted  = notificationItem.IsDeleted;
    item.StyleDefaultDiv = notificationItem.StyleDefaultDiv;
    item.SortOrder = notificationItem.SortOrder;
    item.NotificationType = notificationItem.NotificationType;
    item.NotificationOptions = notificationItem.NotificationOptions;
    item.Code = notificationItem.Code;
    item.NotificationTypeId = notificationItem.NotificationTypeId;
    item.NotificationTypeDescription = notificationItem.NotificationTypeDescription;
    item.ImageFullUrl = notificationItem.ImageFullUrl;
    item.ImageThumbnailUrl = notificationItem.ImageThumbnailUrl;
    item.NotificationFeedbacks = notificationItem.NotificationFeedbacks;
    item.Responses = notificationItem.Responses;
    item.FeaturedImageWidth = notificationItem.FeaturedImageWidth; // default featured image width
    item.FeaturedImageHeight = notificationItem.FeaturedImageHeight; // default featured image height
    item.TopPosition = notificationItem.TopPosition;
    item.LeftPosition = notificationItem.LeftPosition;
    item.ShowFeatureImageBelowTitle = notificationItem.ShowFeatureImageBelowTitle;
    item.IsRespondOnlyOnce = notificationItem.IsRespondOnlyOnce;
    item.EnableClientShowResults = notificationItem.EnableClientShowResults;
    item.EnableResponseAnonymously = notificationItem.EnableResponseAnonymously;
    item.IsAllowNoResponse = notificationItem.IsAllowNoResponse;
    item.IsSentPublish = notificationItem.IsSentPublish;
    item.NotificationDimensions = notificationItem.NotificationDimensions;
    item.NotificationGroup = notificationItem.NotificationGroup;
    item.NotificationGroupId = notificationItem.NotificationGroupId;
    item.Filter = notificationItem.Filter;
    item.FilterId = notificationItem.FilterId;
    item.CreatedBy = notificationItem.CreatedBy;
    item.RecipientCount = notificationItem.RecipientCount;
    item.Modified = notificationItem.Modified;
    item.isVisibleToClients = notificationItem.isVisibleToClients;
    item.IsIncludeImage = notificationItem.IsIncludeImage;
    item.DistinctFeedbackUserCount = notificationItem.DistinctFeedbackUserCount;
    item.HasFeedback = notificationItem.HasFeedback;
    item.PublishNow = notificationItem.PublishNow;
    // item.QuestionnaireNotifications = notificationItem.QuestionnaireNotifications;
    item.ParentQuestionnaireId = notificationItem.ParentQuestionnaireId;
    item.IsSurvey = notificationItem.IsSurvey;
    item.selectedName = notificationItem.Title;
    item.Timeoffset = notificationItem.Timeoffset;
    item.selectedCount = 1;
    item.IsScheduled = notificationItem.IsScheduled;
    item.ShowCorrectFeedbackToClients = notificationItem.ShowCorrectFeedbackToClients;
    item.SelectedChartTypeId = notificationItem.SelectedChartTypeId;
    item.PowerPointToken = notificationItem.PowerPointToken;
    item.CountOfRemainingRemindersToSend = notificationItem.CountOfRemainingRemindersToSend;
    return item;
  }
  constructor(
    public Id: number= 0,
    public CreatedDate: Date = new Date(),
    public IsPublished: boolean = false,
    public PublishDate: Date = new Date(),
    public PublishedDateTime: Date = new Date(),
    public ScheduleDate?: Date,
    public ScheduleUTCDate: Date = new Date(),
    public Title: string = '',
    public Description: string= '',
    public Folder: string= '',
    public Notificationurl: string= '',
    public IsDeleted: boolean= false,
    public StyleDefaultDiv: string = '#f9f9f9',
    public SortOrder: number = 0,
    public NotificationType: string = '',
    public NotificationOptions?: NotificationOption[] | NotificationOption[],
    public Code: string= '',
    public DisableItem?: boolean,
    public NotificationTypeId: number= 0,
    public NotificationTypeDescription: string= '',
    public ImageFullUrl: string= '',
    public ImageThumbnailUrl: string= '',
    public NotificationFeedbacks: number= 0,
    public Responses: number= 0,
    public IconUrl: string = '',
    public FeaturedImageWidth: number = 320, // default featured image width
    public FeaturedImageHeight: number = 200, // default featured image height
    public TopPosition: string = '0',
    public LeftPosition: string = '0',
    public ShowFeatureImageBelowTitle: boolean = false,
    public IsRespondOnlyOnce: boolean = false,
    public EnableClientShowResults: boolean = false,
    public EnableResponseAnonymously: boolean = false,
    public IsAllowNoResponse: boolean = false,
    public IsSentPublish: boolean = false,
    public NotificationDimensions?: NotificationDimension [] | NotificationDimension[],
    public NotificationGroup: string = '',
    public NotificationGroupId: number = 0,
    public Filter: string = '',
    public FilterId: number = 0,
    public CreatedBy: string = '',
    public RecipientCount: number = 0,
    public Modified: Date = new Date(),
    public isVisibleToClients: boolean = true,
    public IsIncludeImage: boolean = false,
    public DistinctFeedbackUserCount: number = 0,
    public HasFeedback: boolean= false,
    public PublishNow: string = 'true',
    public QuestionnaireNotifications?: NotificationItem[] | NotificationItem[],
    public ParentQuestionnaireId: number = 0,
    public IsSurvey: boolean = false,
    public IsNewSurveyItem: boolean = false,
    public Timeoffset: number = 0,
    public HighChartData?: ChoiceNotificationResponseSummary,
    public RankingHighChartData?: RankNotificationResponseSummary,
    public ScalingHighChartData?: DialogueScalingNotificationResponseSummary,
    public UserFeedback?: UserFeedbackModel[] | [],
    public IsScheduled: boolean = false,
    public ShowCorrectFeedbackToClients: boolean = false,
    public SelectedChartTypeId: number = 0,
    public Wordclouds?: DialogueWordcloudData,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    public PowerPointToken?: PowerPointToken,
    public CountOfRemainingRemindersToSend: number = 0,
  ) {
    super(0, '');
  }
}
export class NotificationItemResult {

  constructor(public Notification: NotificationItem) { }
}
