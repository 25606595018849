
import { Component, Prop, toNative, Vue } from 'vue-facing-decorator';
import { NotificationBulkOperation } from '@/entities/BulkOperations/Notification/NotificationBulkOperation';
import loading from '@/components/Common/loading.vue';
import { SignalRModelRequest } from '@/signalRRequest/SignalRModelRequest';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { NotificationSignalRModel } from '@/signalRRequest/Notification/NotificationSignalRModel';
import { StoreHelper } from '@/store/StoreHelper';
import { AppRequestInfo } from '@/entities/AppRequestInfo';
import SubModuleEnum from '@/enums/SubModuleEnum';
import NotificationBatchExport from '@/components/Notification/BatchOperations/NotificationBatchExport.vue';
import NotificationBatchChangeFolder from '@/components/Notification/BatchOperations/NotificationBatchChangeFolder.vue';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationSegmentQuestion } from '@/entities/Notification/NotificationSegmentQuestion';
import { BulkOperations } from '@/entities/BulkOperations/BulkOperations';
import { Root, Store } from '@/main';
@Component({
  components:
  {
    loading,
    ModalPopup,
    NotificationBatchExport,
    NotificationBatchChangeFolder,
  },
})
class NotificationBatchEditPane extends Vue {
  @Prop() private selectedItems?: NotificationItem[];
  @Prop() private folderItems?: string[];
  @Prop() private participantSegments?: NotificationSegmentQuestion[];

  private bulkEditOperations: NotificationBulkOperation = new NotificationBulkOperation(
    Root,
    Store,
    this.$t,
  );
  private signalR: NotificationSignalRModel = new NotificationSignalRModel(
    Root,
    Store,
    this.$t,
  );
  private selectedNotificationIds: number[] = [];
  private localfolderitems?: string[] = [];
  private adminType = '';
  private localSelectedBulkOperationModel: SignalRModelRequest = new SignalRModelRequest();
  private modalPopupVisible = false;
  private showCloseButton = true;
  private modalPopupContent = '';
  private showExportSelected = false;
  private showChangeFolder = false;
  private showLoading = false;
  private readonly BulkDeleteOperation = 'BulkDeleteOperation';
  private showSection_ModalPopup = '';
  private fileDownload = require('js-file-download');
  private evenIndex(index: number): boolean {
    if (index % 2 === 0) {
      return true;
    } else {
      return false;
    }
  }
  private mounted() {
    // set local folder items to pass them onto NotificationBatchChangeFolder pane
    this.localfolderitems = this.folderItems;
    const storeHelper: StoreHelper = new StoreHelper(Store);
    const reqInfo: AppRequestInfo = storeHelper.GetRequestInfoFromStore();
    // get admin type from store who is doing batch peration
    this.adminType = reqInfo.AdminType;
  }
  /* Handle calling of Functions from Click Events in the List of Bulk Operartions Via
  "bulkEditOperations.arrBulkOperations item" */
  private handle_function_call(
    objModel: SignalRModelRequest,
    confirmationMessage: string,
    showConfirmationPopup: boolean,
  ) {
    // check is popup is visible thet means (Clear response or Delete multiple) is selected.
    if (showConfirmationPopup) {
      this.selectedNotificationIds = [];
      // get selected ids in the  listing.
      this.selectedItems!.forEach((element) => {
        this.selectedNotificationIds.push(element.Id);
      });
      this.modalPopupVisible = true;
      this.showSection_ModalPopup = this.BulkDeleteOperation;
      // set popup message for corresponding bulk operation (delete/ clear response, so on..)
      this.modalPopupContent = this.$t(confirmationMessage, {
        selectedCount: this.selectedItems!.length,
      }).toString();
    } else if (objModel.SubModule === SubModuleEnum.DialogueExportSelected) {
      // Show Export pane if bulk export is selected
      this.ShowExportResponsesBatchPane(true);
    } else if (objModel.SubModule === SubModuleEnum.AssignToFolder) {
      // Show Change folder pane if bulk change folder is selected
      this.ShowChangeFoldersBatchPane(true);
    }
    this.localSelectedBulkOperationModel = Object.assign({}, objModel);
  }
  private ShowExportResponsesBatchPane(val: boolean) {
    this.showExportSelected = val;
  }
  private ShowChangeFoldersBatchPane(val: boolean) {
    this.showChangeFolder = val;
  }
  // Hide Clear Response Option From Event Admin
  private ShowBatchOperationOption(item: BulkOperations): boolean {
    // Event admin will not be able to clear response. so we will hide "Clear respose option" from him.
    if (item.Id === 3 && this.adminType === 'Event Admin') {
      return false ;
    } else {
      return true;
    }
  }
  private ConfirmClick(confirmed: boolean) {
    this.modalPopupVisible = false;
    if (confirmed) {
      setTimeout(() => {
        Root.emit('show-notification-popup', true);
        Root.emit('show-notification-loading', true);
      }, 100);

      // START : setting content of SignalR model
      this.localSelectedBulkOperationModel.Heading = this.$t(
        this.localSelectedBulkOperationModel.Heading,
      ).toString();
      this.localSelectedBulkOperationModel.Description = this.$t(
        this.localSelectedBulkOperationModel.SubmitMessage,
        {
          selectedCount: this.selectedItems!.length,
          user: this.signalR.GetUserName(this.localSelectedBulkOperationModel, this.$t('UserName.You').toString()),
        },
      ).toString();
      this.localSelectedBulkOperationModel.SelectedIds = this.selectedNotificationIds;
      // END : setting content of SignalR model

      /* Here the API for bulk operation is called through "PerformNotificationBatchOperation".
      All Bulk operation which have */
      this.signalR.PerformNotificationBatchOperation(
        this.localSelectedBulkOperationModel,
      );
      // uncheck select all checkbox after bulk operation finishes
      Root.emit('notifications-uncheck-select-all');
    } else {
      return;
    }
  }
}
export default toNative(NotificationBatchEditPane);
