
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { VueDraggableNext } from 'vue-draggable-next';
import { NotificationOption } from '@/entities/Notification/NotificationOption';
import ValidationSummary from '@/components/Common/ValidationSummary.vue';
import { Root } from '@/main';
import { reactive } from 'vue';
import { minLength, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

@Component({
  components: {
    draggable: VueDraggableNext,
    ValidationSummary,
  },
})
class NotificationOptionsList extends Vue {
  @Prop() private notificationOptions?: NotificationOption[];
  @Prop() private maximumOptions?: number;
  @Prop() private isDraggable?: boolean;
  @Prop() private isScaling?: boolean;

  // Header texts
  private mainHeading = '';
  private subHeading = '';
  private createNew = '';

  private optionAddCounter = 0;
  private maxOptionsAllowed = 0;
  private localnotificationOptions: NotificationOption[] = [];
  private sortednotificationOptions: NotificationOption[] = [];
  private IsCreateNewLinkDisabled = false;
  private dragging = false;
  private isMobileView = false;
  private optionplaceHolder = '';
  private numberofDefualtIOptions = 2;
  private state = reactive({
    localNotificationOption: [] as NotificationOption[],
  });

  private rules = {
    localNotificationOption: {
      Text: {
        required,
        minLength: minLength(1),
      },
    },
  };
  private status(validation: { $error: any; $dirty: any }) {
    return {
      error: validation.$error,
      dirty: validation.$dirty,
    };
  }
  private v$ = useVuelidate(this.rules, this.state);

  private mounted() {
    // check for ipad and mobile view
    if (window.innerWidth <= 767) {
      this.isMobileView = true;
    } else {
      this.isMobileView = false;
    }
    if (this.isScaling === true) {
      // texts displayed on options component if dialogue type is scaling
      this.mainHeading = this.$t('Dialogue.Statements').toString();
      this.subHeading = this.$t('Dialogue.MinimumStatements').toString();
      this.createNew = this.$t('Dialogue.Createstatement').toString();
      this.optionplaceHolder = this.$t('Dialogue.Input.StatementPlaceHolder').toString();
    } else {
      // texts displayed on options component if dialogue type is Rank / Pick correct / SCQ / MCQ
      this.mainHeading = this.$t('ResponseOptions').toString();
      this.subHeading = this.$t('Dialogue.AtleastTwoOptions').toString();
      this.createNew = this.$t('Dialogue.CreateOption').toString();
      this.optionplaceHolder = this.$t('Dialogue.Input.OptionPlaceHolder').toString();
    }
    // binding dialogue options to local options list
    this.bindOptions(this.notificationOptions!);
    // check if maximum nuber of options has already reached.
    this.checkNumberofExistingOption();
    // set local maximum number of options allwed.
    this.maxOptionsAllowed = this.maximumOptions!;
    /* Emit that handles adding a new option under a dialogue item. This emit is mainly called while
    creating a new dioalogue. A default option one is added under a dialogue.*/
    Root.on(
      'add-new-option-for-create',
      () => {
        for (let i = 0; i < this.numberofDefualtIOptions; i++) {
          this.AddNewOption();
        }
      },
    );
  }
  private bindOptions(options: NotificationOption[]) {
    const self = this;
    self.localnotificationOptions = [];
    options.forEach((value) => {
      self.localnotificationOptions.push(value);
    });
    this.state.localNotificationOption = this.localnotificationOptions;
    // this.localnotificationOptions.sort((a, b) => (a.SortOrder > b.SortOrder) ? 1 : -1);
  }
  // Create New GUID. GUID is another variable used to keep our list of options unique
  private newGuid() {
    const guidFormatter = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
    return guidFormatter.replace(/[xy]/g, function(c) {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
  // Adding a new option under a dialogue
  private AddNewOption() {
    if (this.optionAddCounter === 0) {
      this.optionAddCounter = this.localnotificationOptions.length;
    }
    const alreadyAddedOptionsCount = this.localnotificationOptions.length;
    // Check if maximum option has reached
    if (alreadyAddedOptionsCount < this.maxOptionsAllowed) {
      const item = NotificationOption.createEmpty();
      item.Text = '';
      item.Id = this.optionAddCounter;
      item.SortOrder = this.optionAddCounter + 1;
      item.OptionId = 'option-' + this.newGuid();
      item.IsNewOption = true;
      // item.defaultStyling = 'background-color:#FFF;border: solid #FF0000 2px';
      this.localnotificationOptions.push(item);
      this.IsCreateNewLinkDisabled = true;
      const newOptionId = this.optionAddCounter.toString();
      setTimeout(() => {
        const element = document.getElementById(newOptionId) as HTMLBodyElement;
        if (element !== null && this.optionAddCounter > 2) {
          element.focus();
        }
      }, 100);
      this.optionAddCounter = this.optionAddCounter + 1;
    } else {
      // Max Limit is reached
      // this.errors.push(
      //   this.$t('Dialogue.MaxOptionsReached').toString(),
      // );
      this.IsCreateNewLinkDisabled = true;
    }
    Root.emit('total-options-count',this.localnotificationOptions.length);
  }
  // Draggable component on drag start event
  private draggingStart() {
    this.dragging = true;
  }
  // Draggable component on drag end event
  private draggingEnd() {
    this.dragging = false;
    this.UpdateSortOrder();
  }
  private UpdateSortOrder() {
    // updating sort order of options under a dialogue.
    this.sortednotificationOptions = this.localnotificationOptions!;
    this.sortednotificationOptions.forEach((element, index) => {
      element.SortOrder = index;
    });
    this.localnotificationOptions = this.sortednotificationOptions;
    this.localnotificationOptions = this.localnotificationOptions;
  }
  // Get styling of Options textbox for validations
  private GetTextboxStyling(option: NotificationOption): string {
    return option.defaultStyling;
  }
  // Check if option Name is empty and change its style
  private CheckIfEmptyOption(option: NotificationOption) {
    if (option.Text.trim() === '') {
      option.defaultStyling = 'background-color:#FFF;border: solid #FF0000 2px;';
      this.IsCreateNewLinkDisabled = true;
    } else if (this.optionAddCounter < this.maxOptionsAllowed) {
      option.defaultStyling = '';
      this.IsCreateNewLinkDisabled = false;
    }
    const objIndex = this.localnotificationOptions.findIndex(
      (obj: NotificationOption) => obj.Id === option.Id,
    );
    if (objIndex > -1) {
      // Updated
      this.localnotificationOptions[objIndex] = option;
    }
  }
  // Remove an option from array on click of cross icon
  private RemoveOption(selected: any) {
    if (window.confirm(this.$t('Dialogue.ConfrirmDeleteOption').toString())) {
      const objIndex = this.localnotificationOptions.findIndex(
        (obj: any) => obj.Id === selected.Id,
      );
      if (objIndex > -1) {
        this.localnotificationOptions.splice(objIndex, 1);
      }
      this.IsCreateNewLinkDisabled = false;
    }
    Root.emit('total-options-count',this.localnotificationOptions.length);
  }
  private checkNumberofExistingOption() {
    /* Check number of existing option in a dialogue and disable add new
    option link of maxmium number of option that can be added has already reached */
    if (this.localnotificationOptions !== undefined) {
      this.IsCreateNewLinkDisabled  = this.localnotificationOptions.length === this.maxOptionsAllowed ? true : false;
    }
  }
  @Watch('notificationOptions')
  private onItemPropertyChange(val: NotificationOption[]) {
    this.bindOptions(val);
    this.checkNumberofExistingOption();
  }
  @Watch('localnotificationOptions')
  private onselectedItemPropertyChange(val: NotificationOption[]) {
  // Emit currect state of options (number of option/ option text) to parent pane
    this.$emit('notification-option-added-updated-removed', val);
    // to go through all options text boxes and disable a "Add an option" link if any of the options is empty
    this.localnotificationOptions.forEach((value) => {
      if ( value.Text.trim() === '') {
        this.IsCreateNewLinkDisabled = true;
      }
    });
  }
  @Watch('localnotificationOptions.SortOrder')
  private onselectedOptionSortOrderChange() {
  /* check if sort order of the option is changed (in Scale and Rank and set sort order)
  whenever drag and drop controller is used*/
    this.UpdateSortOrder();
  }

}
export default toNative(NotificationOptionsList);
