
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import NotificationEnum from '@/enums/NotificationEnum';
import { NotificationUserResponseOptionsItem } from '@/entities/Notification/NotificationUserResponseOptionsItem';
import { Root } from '@/main';

@Component
class NotificationUserResponseOptions extends Vue {
  // Properties
  @Prop() private userResponseOptions?: NotificationUserResponseOptionsItem;

  // local variable
  private localUserResponseOptions: NotificationUserResponseOptionsItem | null = null;
  private pickCorrectToolTip = false;
  private showAnswersToolTip = false;
  private responseOnceToolTip = false;

  private mounted() {
  // emit handler for setting values of checkboxes when we open any notification item in Edit pane
    Root.on(
      'notification-UserResponseOptions',
      (userResponseOptions: NotificationUserResponseOptionsItem, notificationTypeId: number) => {
        this.SetUserResponseValuesforEdit(userResponseOptions, notificationTypeId);
      },
    );
  }

  private created() {
    this.localUserResponseOptions = Object.assign(
      {},
      this.userResponseOptions,
    );
  }
  private change() {
    this.$emit('setUserResponseOptions', this.localUserResponseOptions);
  }

  private SetUserResponseValuesforEdit(userResponseOptions: NotificationUserResponseOptionsItem, notificationTypeId: number) {
    /* to hide "Show answers in app" and "allow users to respond once only"
    for pick correct and Survey (Buisness requirement) */
    if (notificationTypeId === NotificationEnum.PickCorrect || notificationTypeId === NotificationEnum.Survey) {
      userResponseOptions.IsOnlyAllowResponseVisible = false;
      userResponseOptions.IsshowAnswersInPhoneVisible = false;
    }
    /* make user anonymous is selectable only until the dialogue is published. If item is published
    user won't be able to make changes to it adimn. So we make this checkbox disabled*/
    userResponseOptions.MakeUserAnonumousDisabled = true;
    this.localUserResponseOptions = userResponseOptions;
  }

  // Show hide the tooltip
  private GetInformationString(val: boolean, type: string) {
    if(type === 'showanswers') {
      this.showAnswersToolTip = val;
    } else if (type === 'responseonce') {
      this.responseOnceToolTip = val;
    } else {
      this.pickCorrectToolTip = val;
    }
  }

  @Watch('userResponseOptions', {deep: true})

  private onChangeUserResponseOptions(val: NotificationUserResponseOptionsItem) {
    this.localUserResponseOptions = val;
  }
}
export default toNative(NotificationUserResponseOptions);
