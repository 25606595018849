import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3727ed11"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ajax-loader" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  id: "notificationEditMode",
  class: "scroll-top editPane edit-pane-height"
}
const _hoisted_4 = {
  key: 2,
  class: "padding-bottom-10"
}
const _hoisted_5 = {
  key: 0,
  class: "right-container-margin"
}
const _hoisted_6 = { class: "inline-flex100 align-items-center" }
const _hoisted_7 = { class: "main-title-width" }
const _hoisted_8 = {
  key: 0,
  class: "main-heading primary-heading"
}
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "main-heading primary-heading" }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { class: "main-heading primary-heading" }
const _hoisted_13 = { class: "inline-flex" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  class: "btn-group",
  style: {"display":"inline-flex !important"}
}
const _hoisted_16 = {
  type: "button",
  class: "btn btn-primary btn-size-split dropdown-toggle dropdown-toggle-split margin-left-0",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false"
}
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "dropdown-menu" }
const _hoisted_19 = {
  key: 1,
  class: "btn-group",
  style: {"display":"inline-flex !important"}
}
const _hoisted_20 = {
  type: "button",
  class: "btn btn-primary btn-size-split dropdown-toggle dropdown-toggle-split margin-left-0",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false"
}
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "dropdown-menu" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { key: 1 }
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "div-send-PushOnPublish" }
const _hoisted_27 = { class: "padding-top-20" }
const _hoisted_28 = {
  key: 0,
  class: "padding-top-20 scheduled-dialogue"
}
const _hoisted_29 = {
  key: 1,
  class: "alert alert-success margin-top-10"
}
const _hoisted_30 = { key: 1 }
const _hoisted_31 = { key: 2 }
const _hoisted_32 = {
  key: 3,
  class: "right-container-margin"
}
const _hoisted_33 = {
  key: 4,
  class: "right-container-margin"
}
const _hoisted_34 = {
  key: 5,
  class: "right-container-margin"
}
const _hoisted_35 = {
  key: 6,
  class: "right-container-margin"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_ModalPopup = _resolveComponent("ModalPopup")!
  const _component_VueModalPopUp = _resolveComponent("VueModalPopUp")!
  const _component_SendPushOnPublish = _resolveComponent("SendPushOnPublish")!
  const _component_ValidationSummary = _resolveComponent("ValidationSummary")!
  const _component_NotificationEditItemPane = _resolveComponent("NotificationEditItemPane")!
  const _component_NotificationSurvey = _resolveComponent("NotificationSurvey")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_NotificationSchedule = _resolveComponent("NotificationSchedule")!
  const _component_NotificationSelectAudienceFilter = _resolveComponent("NotificationSelectAudienceFilter")!
  const _component_NotificationDiscussSelected = _resolveComponent("NotificationDiscussSelected")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.ReloadNotification)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: "overlayDiv",
          class: _normalizeClass({'overlay' : _ctx.ReloadNotification})
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              class: "img-responsive ajax-loader-img",
              src: _ctx.loadingImage,
              alt: "Wait"
            }, null, 8, _hoisted_2)
          ])
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_loading, {
        "show-loading": _ctx.showLoading,
        "border-color": _ctx.loaderBorderColor
      }, null, 8, ["show-loading", "border-color"]),
      (_ctx.modalPopupVisible)
        ? (_openBlock(), _createBlock(_component_ModalPopup, {
            key: 0,
            "show-modal-popup": _ctx.modalPopupVisible,
            "body-heading": _ctx.modalPopupContent,
            "show-close-button": _ctx.showCloseButton,
            "show-cancel-button": _ctx.modalPopupShowCancelButton,
            "cancel-button-text": _ctx.modalPopupCancelButtonText,
            "confirm-button-text": _ctx.modalPopupConfirmButtonText,
            onConfirmClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ConfirmClick($event)))
          }, null, 8, ["show-modal-popup", "body-heading", "show-close-button", "show-cancel-button", "cancel-button-text", "confirm-button-text"]))
        : _createCommentVNode("", true),
      (_ctx.showVueModalPopUp)
        ? (_openBlock(), _createBlock(_component_VueModalPopUp, {
            key: 1,
            "modal-pop-up": _ctx.unsavedVueModalPopUp,
            "is-content-left-aligned": true,
            onClose: _ctx.CloseVueModelPopup,
            onSave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.SaveVueModelPopUp($event)))
          }, null, 8, ["modal-pop-up", "onClose"]))
        : _createCommentVNode("", true),
      (_ctx.ShowNotificationEditDetailView())
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_ctx.IsEditControllersVisible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", null, [
                      _createElementVNode("button", {
                        class: "btn btn-secondary btn-size-default",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Cancel()))
                      }, _toDisplayString(_ctx.$t("CancelButton")), 1)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      (_ctx.isMobileView && !_ctx.localNotificationItem.IsSurvey)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("Dialogue.DialogueItem")), 1))
                        : (!_ctx.isMobileView && !_ctx.localNotificationItem.IsSurvey)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("Dialogue.DialogueItem")), 1)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("Dialogue.SurveyItem")), 1)
                            ]))
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      (!_ctx.localNotificationItem.Id==0 && _ctx.localNotificationItem.IsPublished)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createElementVNode("div", _hoisted_15, [
                              _createElementVNode("button", {
                                type: "button",
                                class: "btn btn-primary btn-size-default dropdown-toggle publish-button-radius split-publish-button",
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.SubmitForValidations($event, _ctx.v$)))
                              }, _toDisplayString(_ctx.$t("Dialogue.Save")), 1),
                              _createElementVNode("button", _hoisted_16, [
                                _createElementVNode("img", { src: _ctx.dropDownIcon }, null, 8, _hoisted_17)
                              ]),
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("a", {
                                    class: "dropdown-item publish-option",
                                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.UnpublishedNotification($event)))
                                  }, _toDisplayString(_ctx.$t("Unpublish")), 1)
                                ])
                              ])
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createElementVNode("button", {
                              type: "button",
                              class: "btn btn-primary btn-size-default dropdown-toggle publish-button-radius split-publish-button",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.SubmitForValidations($event, _ctx.v$)))
                            }, _toDisplayString(_ctx.$t("Publish")), 1),
                            _createElementVNode("button", _hoisted_20, [
                              _createElementVNode("img", { src: _ctx.dropDownIcon }, null, 8, _hoisted_21)
                            ]),
                            _createElementVNode("div", _hoisted_22, [
                              (!_ctx.localNotificationItem.IsPublished && _ctx.localNotificationItem.ScheduleDate == null)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                    _createElementVNode("a", {
                                      class: "dropdown-item publish-option",
                                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.SubmitForValidations($event, _ctx.v$)))
                                    }, _toDisplayString(_ctx.$t("Dialogue.PublishLater")), 1)
                                  ]))
                                : _createCommentVNode("", true),
                              (!_ctx.localNotificationItem.IsPublished && !_ctx.localNotificationItem.Id==0)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                    _createElementVNode("a", {
                                      class: "dropdown-item publish-option",
                                      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.SubmitForValidations($event, _ctx.v$)))
                                    }, _toDisplayString(_ctx.$t("Dialogue.Save")), 1)
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ])),
                      _createElementVNode("div", {
                        class: _normalizeClass(["btn btn-delete", {'btn-delete-ie': _ctx.isIEbrowser}]),
                        onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.ClickDeleteNotification()))
                      }, [
                        _createElementVNode("img", { src: _ctx.deleteIcon }, null, 8, _hoisted_25)
                      ], 2)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_26, [
                    _createVNode(_component_SendPushOnPublish, {
                      "is-sent-publish": _ctx.localNotificationItem.IsSentPublish,
                      "is-published": _ctx.localNotificationItem.IsPublished,
                      onSetIsSentPublishProperties: _ctx.SetIsSentPublishInLocal
                    }, null, 8, ["is-sent-publish", "is-published", "onSetIsSentPublishProperties"])
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    (_ctx.visibleValidationSummary)
                      ? (_openBlock(), _createBlock(_component_ValidationSummary, {
                          key: 0,
                          "error-fields": _ctx.validationErrorFields,
                          onCloseClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.CloseValidationSummary($event)))
                        }, null, 8, ["error-fields"]))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.localNotificationItem.IsScheduled)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                        _createElementVNode("div", null, [
                          _createElementVNode("label", null, [
                            _createTextVNode(_toDisplayString(_ctx.$t("Dialogue.ScheduleMessage")) + " " + _toDisplayString(_ctx.formatScheduleTime(_ctx.localNotificationItem)) + " ", 1),
                            _createElementVNode("a", {
                              class: "hyperLink margin-left-5 Cancel-scheduling",
                              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.ClickCancelScheduling()))
                            }, _toDisplayString(_ctx.$t("Dialogue.CancelScheduling")), 1)
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.messages.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (message) => {
                          return (_openBlock(), _createElementBlock("span", { key: message }, _toDisplayString(message), 1))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (!_ctx.localNotificationItem.IsSurvey)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                  _createVNode(_component_NotificationEditItemPane, {
                    "selected-notification-item": _ctx.localNotificationItem,
                    folders: _ctx.folders,
                    filters: _ctx.filters,
                    "available-custom-category-folders": _ctx.availableCustomCategoryFolders,
                    "available-custom-category-items": _ctx.availableCustomCategoryItems,
                    "available-program-items": _ctx.availableProgramItems
                  }, null, 8, ["selected-notification-item", "folders", "filters", "available-custom-category-folders", "available-custom-category-items", "available-program-items"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_31, [
                  _createVNode(_component_NotificationSurvey, {
                    "selected-notification-item": _ctx.localNotificationItem,
                    folders: _ctx.folders,
                    filters: _ctx.filters
                  }, null, 8, ["selected-notification-item", "folders", "filters"])
                ]))
          ]))
        : _createCommentVNode("", true),
      (_ctx.ShowFileUploadSection())
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createVNode(_component_FileUpload, {
              "heading-title": _ctx.GetImageProcessHeadingTitle(),
              "image-src": _ctx.GetThumbnailImageUrl(),
              "image-full-src": _ctx.GetfeaturedimageFullUrl(),
              "accepted-file-types": _ctx.GetAcceptedFileTypes(),
              "file-extensions": _ctx.GetFileExtensions(),
              validationtype: _ctx.GetValidationType(),
              "icon-image-size-width": _ctx.GetIconImageSize(),
              "icon-image-size-height": _ctx.GetIconImageSize(),
              "validation-message": _ctx.GetValidationMessage(),
              "module-type": _ctx.moduleName,
              "sub-module-name": _ctx.subModuleName,
              type: _ctx.GetImageType(),
              onSelectBack: _ctx.clickOnImage,
              onSetImageIconUrl: _ctx.SetImageIconUrl
            }, null, 8, ["heading-title", "image-src", "image-full-src", "accepted-file-types", "file-extensions", "validationtype", "icon-image-size-width", "icon-image-size-height", "validation-message", "module-type", "sub-module-name", "type", "onSelectBack", "onSetImageIconUrl"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.ShowPublishLater())
        ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
            _createVNode(_component_NotificationSchedule, {
              onSelectClose: _ctx.PublishLaterShowHide,
              onPublishLater: _cache[11] || (_cache[11] = ($event: any) => (_ctx.Submit($event)))
            }, null, 8, ["onSelectClose"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.ShowSelectAudienceFilter())
        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
            _createElementVNode("div", null, [
              _createVNode(_component_NotificationSelectAudienceFilter, {
                "segment-questions": _ctx.localparticipantSegmantQuestions,
                "filter-properties": _ctx.filterViewModel,
                onSetFilter: _cache[12] || (_cache[12] = ($event: any) => (_ctx.SetFilter($event)))
              }, null, 8, ["segment-questions", "filter-properties"])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.ShowDiscussSelected())
        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
            _createVNode(_component_NotificationDiscussSelected, {
              "user-feedbacks": _ctx.localNotificationItem.UserFeedback
            }, null, 8, ["user-feedbacks"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}