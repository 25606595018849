
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import loading from '@/components/Common/loading.vue';
import ModalPopup from '@/components/Common/ModalPopup.vue';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationOption } from '@/entities/Notification/NotificationOption';
import { NotificationDimension } from '@/entities/Notification/NotificationDimension';
import { NotificationFilter } from '@/entities/Notification/NotificationFilter';
import { NotificationUserResponseOptionsItem } from '@/entities/Notification/NotificationUserResponseOptionsItem';
import NotificationHeaderPane from '@/components/Notification/NotificationCommon/NotificationHeaderPane.vue';
import NotificationOptionsList from '@/components/Notification/NotificationCommon/NotificationOptionsList.vue';
import NotificationDimensions from '@/components/Notification/NotificationCommon/NotificationDimensions.vue';
import NotificationUserResponseOptions from '@/components/Notification/NotificationCommon/NotificationUserResponseOptions.vue';
import NotificationFooterPane from '@/components/Notification/NotificationCommon/NotificationFooterPane.vue';
import ChartTypeEnum from '@/enums/ChartTypeEnum';
import NotificationSelectionType from './NotificationSelectionType.vue';
import { NotificationSelectionTypeModel } from '@/entities/Notification/NotificationSelectionTypeModel';
import { Root } from '@/main';


@Component({
  components: {
    loading,
    ModalPopup,
    NotificationHeaderPane,
    NotificationOptionsList,
    NotificationDimensions,
    NotificationUserResponseOptions,
    NotificationFooterPane,
    NotificationSelectionType,
  },
})
class NotificationScaleStatementEditPane extends Vue {
  @Prop() private selectedNotificationItem?: NotificationItem;
  @Prop() private folders?: string[];
  @Prop() private filters?: NotificationFilter[];

  private localNotificationItem: NotificationItem | null = null;
  private readonly maximumOptions = 8;
  private loadingImage: string = require('@/assets/Images/loading.png');
  private localNotificationLoading = false;
  private barChartImg = require('@/assets/Images/Bar-chart-icon.svg');
  private spiderChartImg = require('@/assets/Images/Spider-chart-icon.svg');
  private selectChartType: NotificationSelectionTypeModel[] = [];
  // Notification options
  private notificationOptions: NotificationOption[] = [];

  // Notification Dimensions
  private notificationDimensions: NotificationDimension[] = [];

  // User Response Options
  private userResponseoptions: NotificationUserResponseOptionsItem = NotificationUserResponseOptionsItem.createEmpty();

  private created() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );

    if (this.selectedNotificationItem!.NotificationDimensions! === null || this.selectedNotificationItem!.NotificationDimensions! === undefined) {
      Root.emit('add-new-dimension-for-create'); // Create new Option Case
    } else {
      this.getDimensions(this.selectedNotificationItem!);
    }
  }
  private mounted() {
    if(this.localNotificationItem!.SelectedChartTypeId === undefined || this.localNotificationItem!.SelectedChartTypeId === null || this.localNotificationItem!.SelectedChartTypeId === 0) {
      // setting default selected chart type
      this.localNotificationItem!.SelectedChartTypeId = ChartTypeEnum.SliderChart;
    }
    // setting data for selected chart type to show the chart options according to which chart will be drawn
    this.selectChartType.push({chartTypeId:ChartTypeEnum.SliderChart,chartName:this.$t('Notification.DialogueResult.Barchart').toString(),chartIcon: this.barChartImg});
    this.selectChartType.push({chartTypeId:ChartTypeEnum.SpiderChart,chartName:this.$t('Notification.DialogueResult.Spiderchart').toString(),chartIcon: this.spiderChartImg});
    if (this.selectedNotificationItem!.Id === 0 && this.selectedNotificationItem!.NotificationOptions === undefined) {
      Root.emit('add-new-option-for-create'); // Create new default Option in Case of create new

    } else if (this.selectedNotificationItem!.Id > 0 && this.selectedNotificationItem!.NotificationOptions === undefined && this.selectedNotificationItem!.IsNewSurveyItem) {
      Root.emit('add-new-option-for-create'); // Create new Option Case in case of create new Sub item in Survey
    } else {
      this.getOptions();
      Root.emit('total-options-count',this.localNotificationItem!.NotificationOptions!.length);
    }
    this.showUserResponseOptions();

    if (this.selectedNotificationItem!.NotificationDimensions! === null || this.selectedNotificationItem!.NotificationDimensions! === undefined) {
    // Create new dimensions in Case of create new Scaling type dialogue
      Root.emit('add-new-dimension-for-create');
    } else {
    // set dimension in existing dialogue item
      this.getDimensions(this.selectedNotificationItem!);
    }
  }
  private getOptions() {
    const self = this;
    self.notificationOptions = [];
    this.selectedNotificationItem!.NotificationOptions!.forEach((value) => {
      self.notificationOptions.push(value);
    });
  }
  private getDimensions(notificationItem: NotificationItem) {
    const self = this;
    self.notificationDimensions = [];
    notificationItem.NotificationDimensions!.forEach((value) => {
      self.notificationDimensions.push(value);
    });
  }
  private SetLocalNotificationOptions(options: NotificationOption[]) {
  // Saves any changes in options in Local Notification
    this.localNotificationItem!.NotificationOptions = options;
  }
  private UpdateNotificationCorrectOptionOptions() {
  // here
  }
  private SetlocalNotificationHeaderProperties(notificationItem: NotificationItem) {
  // Set following Notification header properties
    this.localNotificationItem!.Title = notificationItem.Title;
    this.localNotificationItem!.Description = notificationItem.Description;
    this.localNotificationItem!.IsSentPublish = notificationItem.IsSentPublish;
    this.localNotificationItem!.NotificationTypeDescription = notificationItem.NotificationTypeDescription;
    this.localNotificationItem!.PublishedDateTime = notificationItem.PublishedDateTime;
    this.localNotificationItem!.ScheduleDate = notificationItem.ScheduleDate;
    this.localNotificationItem!.ScheduleUTCDate = notificationItem.ScheduleUTCDate;
  }
  private showUserResponseOptions() {
    // Setting "Allow response only once"
    this.userResponseoptions.OnlyAllowResponseValue = this.localNotificationItem!.IsRespondOnlyOnce!;
    // Setting "Show results in app"
    this.userResponseoptions.ShowAnswersInPhoneValue = this.localNotificationItem!.EnableClientShowResults!;
    // Setting "Make participane anonymous while answering"
    this.userResponseoptions.MakeUserAnonumousValue = this.localNotificationItem!.EnableResponseAnonymously!;
    // Making "Make participane anonymous while answering" disabled if item is published
    if (this.localNotificationItem!.IsPublished) {
      this.userResponseoptions.MakeUserAnonumousDisabled = true;
    }
  }
  private SetDimensions(notificationDimension: NotificationDimension) {
  // Saves any changes in dimesion in Local Notification
    this.localNotificationItem!.NotificationDimensions = JSON.parse(JSON.stringify(notificationDimension));
  }
  private setUserResponseOptions(event: any) {
    /* handeling any changes made in checkboxes "Allow response only once", "Show answers in App",
    "Make participane anonymous while answering"*/
    const newUserResponseItem: NotificationUserResponseOptionsItem = event;
    this.localNotificationItem!.IsRespondOnlyOnce = newUserResponseItem.OnlyAllowResponseValue;
    this.localNotificationItem!.EnableClientShowResults = newUserResponseItem.ShowAnswersInPhoneValue;
    this.localNotificationItem!.EnableResponseAnonymously = newUserResponseItem.MakeUserAnonumousValue;
  }
  private SetSelectedFolder(folder: string) {
    this.localNotificationItem!.Folder = folder;
  }
  private SetAccessCode(accessCode: string) {
    this.localNotificationItem!.Code = accessCode;
  }
  private SetFilter(filterId: number) {
  // Set filter Id and Filter name for Saving
    this.localNotificationItem!.FilterId = filterId;
    if (filterId > 0 ) {
      const objindex = this.filters!.findIndex(
        (obj: NotificationFilter) => obj.Id === filterId,
      );
      if (objindex > -1) {
        this.localNotificationItem!.Filter = this.filters![objindex].FilterName;
      }
    }
  }
  private ChangeSelectedChartTypeId(id: number) {
    this.localNotificationItem!.SelectedChartTypeId = id;
  }
  @Watch('selectedNotificationItem')
  private onNotificationItemPropertyChange(val: NotificationItem) {
    // Updating options in an Notification
    if (val.NotificationOptions !== undefined) {
    // Updating local Notification object
      this.getOptions();
      this.localNotificationItem = val;
    }

    if (this.selectedNotificationItem!.NotificationDimensions! === null || this.selectedNotificationItem!.NotificationDimensions! === undefined) {
      Root.emit('add-new-dimension-for-create'); // Create new dimension Case for new item
    } else {
      this.getDimensions(val);
    }
    this.showUserResponseOptions();
  }
  @Watch('localNotificationItem')
  private onlocalItemPropertyChange(newval: NotificationItem) {
  // any edits made in Local Notification Object must be reflected in NotificationEditItemPane
    Root.emit('notification-edited', newval);
  }
}
export default toNative(NotificationScaleStatementEditPane);
