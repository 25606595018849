import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "inline-flex description" }
const _hoisted_4 = { class: "padding-1 margin-left-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotificationHeaderPane = _resolveComponent("NotificationHeaderPane")!
  const _component_NotificationSelectionType = _resolveComponent("NotificationSelectionType")!
  const _component_NotificationUserResponseOptions = _resolveComponent("NotificationUserResponseOptions")!
  const _component_NotificationFooterPane = _resolveComponent("NotificationFooterPane")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_NotificationHeaderPane, {
        "selected-notification-item": _ctx.localNotificationItem,
        onSetHeaderProperties: _cache[0] || (_cache[0] = ($event: any) => (_ctx.SetlocalNotificationHeaderProperties($event)))
      }, null, 8, ["selected-notification-item"])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_NotificationSelectionType, {
        "selected-notification-item": _ctx.localNotificationItem,
        "select-chart-type": _ctx.selectChartType,
        onChangeSelectedChartTypeId: _ctx.ChangeSelectedChartTypeId
      }, null, 8, ["selected-notification-item", "select-chart-type", "onChangeSelectedChartTypeId"])
    ]),
    (!_ctx.localNotificationItem.IsSurvey)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_NotificationUserResponseOptions, {
              "user-response-options": _ctx.userResponseoptions,
              onSetUserResponseOptions: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setUserResponseOptions($event)))
            }, null, 8, ["user-response-options"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_NotificationFooterPane, {
              "is-create-new": _ctx.localNotificationItem.Id == 0 ? true : false,
              folders: _ctx.folders,
              folder: _ctx.localNotificationItem.Folder,
              accesscode: _ctx.localNotificationItem.Code,
              filter: _ctx.localNotificationItem.Filter,
              "filter-id": _ctx.localNotificationItem.FilterId,
              "is-published": _ctx.localNotificationItem.IsPublished,
              "scheduled-date": _ctx.localNotificationItem.ScheduleDate,
              filters: _ctx.filters,
              onSetGroup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.SetSelectedFolder($event))),
              onSetAccessCode: _cache[3] || (_cache[3] = ($event: any) => (_ctx.SetAccessCode($event))),
              onSetFilter: _cache[4] || (_cache[4] = ($event: any) => (_ctx.SetFilter($event)))
            }, null, 8, ["is-create-new", "folders", "folder", "accesscode", "filter", "filter-id", "is-published", "scheduled-date", "filters"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.localNotificationItem.IsSurvey)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("label", _hoisted_3, [
            _withDirectives(_createElementVNode("input", {
              id: "allowNoResponse",
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localNotificationItem.IsAllowNoResponse) = $event)),
              type: "checkbox",
              style: {"margin-left":"25px"}
            }, null, 512), [
              [_vModelCheckbox, _ctx.localNotificationItem.IsAllowNoResponse]
            ]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("Dialogue.IsAllowNoresponse")), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}