
import { Component, Prop, toNative, Vue, Watch } from 'vue-facing-decorator';
import { NotificationItem } from '@/entities/Notification/NotificationItem';
import { NotificationOption } from '@/entities/Notification/NotificationOption';
import { NotificationFilter } from '@/entities/Notification/NotificationFilter';
import { NotificationUserResponseOptionsItem } from '@/entities/Notification/NotificationUserResponseOptionsItem';
import NotificationHeaderPane from '@/components/Notification/NotificationCommon/NotificationHeaderPane.vue';
import NotificationOptionsList from '@/components/Notification/NotificationCommon/NotificationOptionsList.vue';
import NotificationCorrectOption from '@/components/Notification/NotificationCommon/NotificationCorrectOption.vue';
import NotificationUserResponseOptions from '@/components/Notification/NotificationCommon/NotificationUserResponseOptions.vue';
import NotificationFooterPane from '@/components/Notification/NotificationCommon/NotificationFooterPane.vue';
import NotificationEnum from '@/enums/NotificationEnum';
import ChartTypeEnum from '@/enums/ChartTypeEnum';
import NotificationSelectionType from './NotificationSelectionType.vue';
import { NotificationSelectionTypeModel } from '@/entities/Notification/NotificationSelectionTypeModel';
import { Root } from '@/main';

@Component({
  components: {
    NotificationHeaderPane,
    NotificationOptionsList,
    NotificationCorrectOption,
    NotificationUserResponseOptions,
    NotificationFooterPane,
    NotificationSelectionType,
  },
})
class NotificationPickCorrectAnswerEditPane extends Vue {
  // Component properties
  @Prop() private selectedNotificationItem?: NotificationItem;
  @Prop() private folders?: string[];
  @Prop() private filters?: NotificationFilter[];

  // Read only properties
  private readonly maximumOptions = 30;
  private selectChartType: NotificationSelectionTypeModel[] = [];

  // Local variables
  private localNotificationItem: NotificationItem | null = null;
  private notificationOptions: NotificationOption[] = [];
  private userResponseoptions: NotificationUserResponseOptionsItem = NotificationUserResponseOptionsItem.createEmpty();
  private barChartImg = require('@/assets/Images/Bar-chart-icon.svg');
  private pieChartImg = require('@/assets/Images/Pie-chart-icon.svg');

  private created() {
    this.localNotificationItem = Object.assign(
      {},
      this.selectedNotificationItem,
    );
    this.showUserResponseOptions();
  }
  private mounted() {
    if(this.localNotificationItem!.SelectedChartTypeId === undefined || this.localNotificationItem!.SelectedChartTypeId === null || this.localNotificationItem!.SelectedChartTypeId === 0) {
      // setting default selected chart type
      if(this.localNotificationItem!.IsSurvey) {
        this.localNotificationItem!.SelectedChartTypeId = ChartTypeEnum.PieChart;
      } else {
        this.localNotificationItem!.SelectedChartTypeId = ChartTypeEnum.BarChart;
      }
    }
    // setting data for selected chart type to show the chart options according to which chart will be drawn
    this.selectChartType.push({chartTypeId:ChartTypeEnum.BarChart,chartName:this.$t('Notification.DialogueResult.Barchart').toString(),chartIcon: this.barChartImg});
    this.selectChartType.push({chartTypeId:ChartTypeEnum.PieChart,chartName:this.$t('Notification.DialogueResult.Piechart').toString(),chartIcon: this.pieChartImg});
    if (
      this.selectedNotificationItem!.Id === 0 &&
      this.selectedNotificationItem!.NotificationOptions === undefined
    ) {
      this.localNotificationItem!.IsRespondOnlyOnce = true; // Pick correct can be responded only once.
      Root.emit('add-new-option-for-create'); // Create new Option Case

    } else if (
      this.selectedNotificationItem!.Id > 0 &&
      this.selectedNotificationItem!.NotificationOptions === undefined &&
      this.selectedNotificationItem!.IsNewSurveyItem
    ) {
      Root.emit('add-new-option-for-create'); // Create new Option Case
    } else {
      this.getOptions();
    }
    this.showUserResponseOptions();
  }
  private getOptions() {
    const self = this;
    self.notificationOptions = [];
    this.selectedNotificationItem!.NotificationOptions!.forEach((value) => {
      self.notificationOptions.push(value);
    });
  }

  // #region header
  private SetlocalNotificationHeaderProperties(
    notificationItem: NotificationItem,
  ) {
    // Set following Notification header properties
    this.localNotificationItem!.Title = notificationItem.Title;
    this.localNotificationItem!.Description = notificationItem.Description;
    this.localNotificationItem!.IsSentPublish = notificationItem.IsSentPublish;
    this.localNotificationItem!.NotificationTypeDescription = notificationItem.NotificationTypeDescription;
    this.localNotificationItem!.PublishedDateTime = notificationItem.PublishedDateTime;
    this.localNotificationItem!.ScheduleDate = notificationItem.ScheduleDate;
    this.localNotificationItem!.ScheduleUTCDate = notificationItem.ScheduleUTCDate;
  }
  // #endregion header

  // #region options
  private SetLocalNotificationOptions(options: NotificationOption[]) {
    this.localNotificationItem!.NotificationOptions = options;
  }
  // #endregion options

  // #region correct option
  private UpdateNotificationCorrectOptionOptions(selectedId: number) {
    this.localNotificationItem!.NotificationOptions!.forEach((value) => {
      value.IsCorrectOption = value.Id === selectedId ? true : false;
    });
  }
  // #endregion correct option

  // #region user response options
  private showUserResponseOptions() {
    if(this.localNotificationItem!.IsSurvey) {
      this.userResponseoptions.IsOnlyAllowResponseVisible = false;
      this.userResponseoptions.IsshowAnswersInPhoneVisible = false;
      this.userResponseoptions.OnlyAllowResponseValue = false;
      this.userResponseoptions.ShowAnswersInPhoneValue = false;
      this.userResponseoptions.MakeUserAnonumousValue = false;
      this.userResponseoptions.MakeUserAnonumousDisabled = false;
      this.userResponseoptions.ShowMakeUserAnonumous = false;
      // Making "Give correct/incorrect feedback to participants" enable for Pick Correct only
      if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.PickCorrect) {
        this.userResponseoptions.IsShowCorrectIncorrectFeedback = true;
        this.userResponseoptions.ShowCorrectFeedbackToClients = this.localNotificationItem!.ShowCorrectFeedbackToClients;
      }
    } else {
      // "Allow response only once" must not be visible in Pick Correct
      this.userResponseoptions.IsOnlyAllowResponseVisible = true;
      // "Show answers in app" must not be visible in Pick Correct
      this.userResponseoptions.IsshowAnswersInPhoneVisible = true;
      // Setting "Allow response only once"
      this.userResponseoptions.OnlyAllowResponseValue = this.localNotificationItem!.IsRespondOnlyOnce!;
      // Setting "Show results in app"
      this.userResponseoptions.ShowAnswersInPhoneValue = this.localNotificationItem!.EnableClientShowResults!;
      // Setting "Make participane anonymous while answering"
      this.userResponseoptions.MakeUserAnonumousValue = this.localNotificationItem!.EnableResponseAnonymously!;
      // Making "Make participane anonymous while answering" disabled if item is published
      if (this.localNotificationItem!.IsPublished) {
        this.userResponseoptions.MakeUserAnonumousDisabled = true;
      }
      // Making "Give correct/incorrect feedback to participants" enable for Pick Correct only
      if (this.localNotificationItem!.NotificationTypeId === NotificationEnum.PickCorrect) {
        this.userResponseoptions.IsShowCorrectIncorrectFeedback = true;
        this.userResponseoptions.ShowCorrectFeedbackToClients = this.localNotificationItem!.ShowCorrectFeedbackToClients;
      }
      this.userResponseoptions.OnlyAllowResponseValue = this.localNotificationItem!.IsRespondOnlyOnce;
    }

  }
  private setUserResponseOptions(event: any) {
    /* handeling any changes made in checkboxes "Allow response only once", "Show answers in App",
    "Make participane anonymous while answering"*/
    const newUserResponseItem: NotificationUserResponseOptionsItem = event;
    this.localNotificationItem!.IsRespondOnlyOnce =
      newUserResponseItem.OnlyAllowResponseValue;
    this.localNotificationItem!.EnableClientShowResults =
      newUserResponseItem.ShowAnswersInPhoneValue;
    this.localNotificationItem!.EnableResponseAnonymously =
      newUserResponseItem.MakeUserAnonumousValue;
    this.localNotificationItem!.ShowCorrectFeedbackToClients =
      newUserResponseItem.ShowCorrectFeedbackToClients;
  }
  // #endregion user response option

  // #region footer
  private SetSelectedFolder(folder: string) {
    this.localNotificationItem!.Folder = folder;
  }
  private SetAccessCode(accessCode: string) {
    this.localNotificationItem!.Code = accessCode;
  }
  private SetFilter(filterId: number) {
    // Set filter Id and Filter name for Saving
    this.localNotificationItem!.FilterId = filterId;
    if (filterId > 0) {
      const objindex = this.filters!.findIndex(
        (obj: NotificationFilter) => obj.Id === filterId,
      );
      if (objindex > -1) {
        this.localNotificationItem!.Filter = this.filters![
          objindex
        ].FilterName;
      }
    }
  }
  private ChangeSelectedChartTypeId(id: number) {
    this.localNotificationItem!.SelectedChartTypeId = id;
  }
  // #endregion footer

  // #region Watch
  @Watch('selectedNotificationItem')
  private onNotificationItemPropertyChange(
    val: NotificationItem,
  ) {
    if (val.NotificationOptions !== undefined) {
      // Updating options in an Notification
      this.getOptions();
      // Updating local Notification object
      this.localNotificationItem = val;
    }
    this.showUserResponseOptions();
  }
  @Watch('localNotificationItem')
  private onlocalItemPropertyChange(
    newval: NotificationItem,
  ) {
    // any edits made in Local Notification Object must be reflected in NotificationEditItemPane
    Root.emit('notification-edited', newval);
  }
}
export default toNative(NotificationPickCorrectAnswerEditPane);
// #endregion Watch
